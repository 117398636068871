<template>
  <div id="payOrder">
    <div class="f18 mb15">选择收货地址</div>
    <section class="addressList flex align-center flex-wrap mb10">
      <template v-for="(item, index) in addressList">
        <div
          class="pointer"
          :class="[
            index == index_choose ? 'addressItem_choose' : 'addressItem',
            'item',
          ]"
          :key="index"
          @click="chooseAddress(index)"
        >
          <div class="name">
            {{ item.province }} {{ item.city }} ({{ item.consigneeName }}收)
          </div>
          <div class="mb15">{{ item.detail }}</div>
          <!-- <div class="f14 change pointer" @click="editAddress(item)">修改</div> -->
          <div class="default" v-if="item.isDefault == 1">默认地址</div>
        </div>
      </template>
      <div
        class="addBox flex align-center justify-center"
        @click="goAddress('addAddress')"
      >
        <i class="el-icon-plus"></i>
        <div class="f16">新增收货地址</div>
      </div>
    </section>
    <div
      class="moreAddress flex justify-between align-center mb20"
      @click="showAllAddress"
    >
      <div class="pointer">{{ addressText }}</div>
    </div>
    <section class="otherInfo">
      <div class="title">商品清单:</div>
    </section>
    <section class="listBox mb30">
      <div class="listTitle">
        <div>商品</div>
        <div>规格</div>
        <div>单价</div>
        <div>运费</div>
        <div>数量</div>
        <div>小计</div>
      </div>
      <template v-for="(item, index) in productList">
        <div class="productDetail" :key="index">
          <div class="proInfo flex">
            <el-image
              :src="item.logo"
              fit="cover"
              style="width: 130px; height: 130px"
            />
            <div class="proName f16 ml30">{{ item.productName }}</div>
          </div>
          <div class="specs text-deepGray">{{ item.normName }}</div>
          <div class="text-deepGray">￥{{ item.price }}</div>
          <div class="text-deepGray">{{ item.mailAmount }}</div>
          <div class="text-deepGray">{{ item.num }}</div>
          <div class="text-orange">
            ￥{{ ((item.price+ item.mailAmount)*item.num).toFixed(2)}}
          </div>
          <!-- <div class="mainInfo flex align-center mb40">
            <el-image class="img mr60" fit="cover" :src="item.logo" />
            <div class="specBox flex flex-column justify-between align-start mr100">
              <div class="f29">{{ item.productName }}</div>
              <div class="spec f18">{{ item.normName }}</div>
              <div class="flex align-center">
                <div class="mr20 f25">数量</div>
                <div class="flex align-center">
                  <i
                    @click.prevent="reduce"
                    class="el-icon-remove-outline mr5 activeIcon"
                    style="font-size: 17px"
                  />
                  <div class="num mr5">{{ item.num }}</div>
                  <i
                    @click.prevent="add"
                    class="el-icon-circle-plus-outline mr5 activeIcon"
                    style="font-size: 17px"
                  />
                </div>
                <div class="f18">件</div>
              </div>
            </div>
          </div>

          <div class="lineInfo flex align-center mb20 f25">
            <div>价格明细：</div>
          </div>
          <div class="lineInfo flex align-center mb20 f25">
            <div>商品单价：</div>
            <div>{{ item.price }}</div>
          </div>
          <div class="lineInfo flex align-center mb20 f25">
            <div>商品数量：</div>
            <div>{{ item.num }}</div>
          </div>
          <div class="lineInfo flex align-center mb20 f25">
            <div class="text-gray">商品总价：</div>
            <div class="text-red">{{ item.num * item.price }}</div>
          </div> -->
        </div>
      </template>
    </section>
    <div class="lineInfo flex justify-end align-center mb20">
      <div class="lineTitle mr20">订单总价:</div>
      <div class="text-red f18">{{ totalMoney}}</div>
    </div>
    <section class="otherInfo">
      <div class="title">其他信息：</div>
    </section>
    <div class="lineInfo flex align-center mb20 f25">
      <div class="mr20 lineTitle pl40">代理商推荐码:</div>
      <el-row>
        <!-- <el-input
          @blur.native.capture="getReferralCode"
          v-model="agentPromptCode"
         readonly="true"
          @click="open"
          placeholder="请输入代理商推荐码"
        ></el-input> -->
        <div class="agent pointer" @click="open" style="padding-left: 5%">
          {{ this.agentPromptCode }}
        </div>
      </el-row>
    </div>
    <div class="lineInfo flex align-center mb20 f25">
      <div class="mr50 lineTitle pl40">园所名称:</div>
      <!-- <el-input
        v-model="agentGardenName"
        placeholder="请输入园所名称"
      ></el-input> -->
      <div class="agent">
        {{ this.agentGardenName }}
      </div>
    </div>
    <section class="">
      <section>
        <div class="title">请选择支付方式:</div>
      </section>
      <div class="lineInfo flex align-center mb20 f25">
        <div class="lineTitle mr50 pl40">支付方式:</div>
        <div class="payBox flex align-center">
          <template v-for="(item, index) in payType">
            <div
              :key="index"
              class="payItem flex align-center justify-center"
              :class="[currPay == index ? 'activePayItem' : '']"
              @click="choosePay(index)"
            >
              <div class="mr10 imgBox">
                <el-image :src="item.url" fit="contain" style="width: 20px" />
              </div>

              <div class="f16 text-black">{{ item.text }}</div>
            </div>
          </template>
        </div>
        <!-- <div class="radioBox flex align-center justify-around">
          <el-radio v-model="radio" label="1">
            <div class="flex align-center">
              <el-image class="mr20" :src="require('../../assets/img/practice/zfb.png')" style="width: 40px" />
              <div class="f25 text-black">支付宝</div>
            </div>
          </el-radio>
          <el-radio v-model="radio" label="2">
            <div class="flex align-center">
              <el-image class="mr20" :src="require('../../assets/img/practice/wx.png')" style="width: 40px" />
              <div class="f25 text-black">微信支付</div>
            </div></el-radio
          >
        </div> -->
      </div>
    </section>
    <section class="payBtn">
      <el-button class="btn" @click="addOrderList">立即支付</el-button>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //   付款方式
      cart:'',
      radio: "0",
      city: "",
      area: "",
      consigneeName: "",
      consigneePhone: "",
      province: "",
      productList: {},
      isDefault: "",
      num: "1",
      memberCode: "",
      addressList: [],
      productCount: "",
      price: "",
      orderAmount: "",
      agentGardenName: "",
      agentPromptCode: "请输入代理商推荐码",
      logo: "",
      cover: "",
      address: {},
      index_choose: 0,
      consigneeAddress: "",
      list: {},
      isAll: false,
      currPay: 0,
      payType: [
        { url: require("../../assets/img/practice/zfb.png"), text: "支付宝" },
        { url: require("../../assets/img/practice/wx.png"), text: "微信支付" },
      ],
      totalMoney:'',
    };
  },
  computed: {
    // allMoney() {
    //   var a = this.productList;
    //   let money = 0;
    //   this.productList.forEach((v) => {
    //     let singlePrice = v.price + v.mailAmount;
    //     money += v.num * singlePrice;
    //   });
    //   // return money;
    //   this.totalMoney=money
    // },
    allnum() {
      let num = 0;
      this.productList.forEach((v) => {
        num += v.num ;
      });
      return num;
    },
    addressText() {
      if (this.isAll) {
        return "收起";
      } else {
        return "显示全部地址";
      }
    },
  },
  methods: {
    allMoney() {
      var a = this.productList;
      let money = 0;
      this.productList.forEach((v) => {
        let singlePrice = v.price + v.mailAmount;
        money += v.num * singlePrice;
      });
      // return money;
      // this.totalMoney=money
       this.totalMoney=money.toFixed(2)
      // return  this.totalMoney;
    },
    getReferralCode() {
      var getData = {};
      getData.referralCode = this.agentPromptCode;
     
      if (getData.referralCode == "") {
      
        this.agentGardenName = "";
      } else {
        this.$api.notice.getReferralCode(getData).then((res) => {
         
          if (res.data.code == 200) {
            if (res.data.data == null) {
              this.$message({
                message: "该代理商不存在！",
                type: "warning",
                offset:'400'
              });
              this.agentGardenName=''
              return
            } else {
              this.agentGardenName = res.data.data.gardenName;
            }
          }
        });
      }
    },

    editAddress(item) {
      this.isAddAddress = true;
      this.isEdit = true;
      this.id = item.id;
      this.province = [item.province, item.city, item.area];
      this.chosenProvince = item.province;
      this.chosenCity = item.city || "";
      this.chosenArea = item.area || "";
      this.form = {
        detailAdd: item.detail,
        emailCode: item.emailCode,
        consigneeName: item.consigneeName,
        consigneePhone: item.consigneePhone,
        isDefault: item.isDefault == "1" ? true : false,
      };
    },
    chooseAddress(index) {
      this.address = this.addressList[index];
      this.index_choose = index;
    },
       //支付
    addOrderList() {
      var getData = {};
      getData.orderItemList = this.productList;
      getData.consigneeName = this.address.consigneeName;
      getData.consigneeAddress =
        this.address.province +
        this.address.city +
        this.address.area +
        this.address.detail;
      getData.productCount = this.allnum;
      getData.consigneePhone = this.address.consigneePhone;
      getData.agentPromptCode = this.agentPromptCode;
      getData.agentGardenName = this.agentGardenName;
      getData.orderAmount = this.totalMoney;
      getData.mailAmount=0
      this.productList.forEach((mail)=>{
        let totalMail=mail.mailAmount*mail.num
        getData.mailAmount+=totalMail
      })
      debugger
      // getData.payType == "alipay";
      getData.payType = this.radio == 0 ? "alipay" : "weChat";
      getData.memberCode = this.memberCode;
      
      // if (getData.agentPromptCode == "" || getData.agentGardenName == "") {
      //   this.$message({
      //     message: "请填写代理商",
      //     type: "warning",
      //     offset:'400',
      //   });
      //   return;
      // } else {
        if (this.cart==2) {
          this.$api.notice.deleteShopping(this.productList).then((res) => {
            });
        }
            this.$api.notice.addOrder(getData).then((res) => {
          if ((res.data.code == 200)) {
            let payTitle=  res.data.data.wxPay
            if (payTitle!=undefined) {
            this.$router.push({path:'/weiMiddle',
               query: {
                content: res.data.data.wxPay,
                orderNumber: res.data.data.orderNumber,
                totalMoney:this.totalMoney
              },  
            })
            }else{
            //支付宝
            let routeData = this.$router.resolve({
              path: "/alipay/middle",
              query: {
                content: res.data.data,
              },
            });
            window.open(routeData.href, "_blank");
            }
          }
        });
      
        
      // }
    },
    del() {
      this.list = this.productList;
      this.$api.notice.deleteShopping(this.list).then((res) => {
        if ((res.data.code == 200)) {
        }
      });
    },
    addAddressList() {
      this.$api.notice.addAddressList(memberCode).then((res) => {
        if ((res.data.code == 200)) {
        }
      });
    },
    getAddressList(num) {
      var getData = {};
      getData.memberCode = this.memberCode;
      this.$api.notice.getAddressList(getData).then((res) => {   
        if ((res.data.code = 200)) {
          //num为2代表切割的意思
          if (num == 2) {
            //将默认地址移动到第一位
            res.data.data.forEach((item, index) => {
              if (item.isDefault == 1) {
                //  this.address =item
                res.data.data.splice(index, 1);     
                res.data.data.splice(0, 0, item);
              }
            });
            if (res.data.data.length < 2) {
              //默认地址列表长度为2条，不做切割
              this.addressList = res.data.data;
            } else {
              //  其他情况展示后两条
              this.addressList = res.data.data.slice(0, 2);
            }
          } else {
            //不切割处理返回所有的地址列表
            this.addressList = res.data.data;
          }
        }
        //地址列表加载默认选择最新的地址
        // this.address = this.addressList[0];
        this.addressList.forEach((item, index) => {
          if (item.isDefault == 1) {
            //  this.address =item
            this.addressList.splice(index, 1);
            
            this.addressList.splice(0, 0, item);
          }
        });
        //地址列表加载默认选择最新的地址
        this.address = this.addressList[0];   
      });
    }, //
    reduce() {
      if (this.num == 1) {
        this.$message({
          message: "数量最低选择一件！",
          type: "warning",
          offset:'400',
        });
      } else {
        this.num--;
      }
    },
    add() {
      this.num++;
    },
    goAddress(val) {
      this.$router.push({
        path: "/user",
        query: {
          myAddress: val,
        },
      });
    },
    showAllAddress() {
      if (this.isAll) {
        //判断是否展示所有，isAll是ture（收起）传2改变状态为false。
        this.getAddressList("2");
        this.isAll = false;
      } else {
        this.getAddressList();
        this.isAll = true;
      }
    },
    // 选择支付方式
    choosePay(index) {
      this.currPay = index;
      this.radio = index;
    }, //
    open() {
      this.$prompt("请输入代理商推荐码", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          if (value == null) {
            this.agentPromptCode = "请输入代理商推荐码";
            this.agentGardenName = "";
          } else {
            this.agentPromptCode = value;
            this.getReferralCode();
          }
          
        })
        .catch(() => {});
    },
  },
  created() {
    this.memberCode = JSON.parse(sessionStorage.getItem("userInfo")).memberCode;
    let b = JSON.parse(this.$route.query.productList);
    console.log('productList',this.$route.query.productList)
    if (this.$route.query) {
      //默认存到会话存储

      let temp = JSON.parse(this.$route.query.productList);
      if (Array.isArray(temp) === false) {
        this.productList = [temp];
      } else {
        this.productList = b;
        this.cart = this.$route.query.cart;
      }
    }
    //创建的时候就做切割处理。创建的时候，地址数组，不做切割处理，需要展示最后2条地址。在查的时候默认是判断返回数组长度，做切割
    this.getAddressList("2");
    this.allMoney()
    
  },
};
</script>

<style lang="less" scoped>
#payOrder {
  width: 985px;
  padding: 30px 0;
  margin: 0 auto;
  text-align: left;
}
.addressList {
  font-size: 16px;
  .addBox {
    width: 300px;
    height: 150px;
    margin-bottom: 10px;
    border: 2px solid #a5a3a3;
    border-radius: 5px;
    font-size: 30px;
    text-align: center;
    line-height: 150px;
  }
  .addBox:hover {
    cursor: pointer;
  }
  .item {
    margin-right: 10px;
  }
  .addressItem {
    width: 300px;
    min-height: 150px;
    padding: 14px 17px;
    margin-bottom: 10px;
    border: 2px solid black;
    border-radius: 5px;
    // &.addressItem:nth-child(n) {
    //   margin-right: 20px;
    //   margin-bottom: 20px;
    // }
    // &.addressItem:nth-child(3n) {
    //   margin-right: 0px;
    // }
    .name {
      padding: 5px;
      margin-bottom: 16px;
      border-bottom: 2px solid #dcdcdc;
    }
    .change {
      width: 50px;
      color: #3387ef;
    }
  }

  .addressItem_choose {
    width: 300px;
    min-height: 150px;
    margin-bottom: 10px;
    padding: 14px 17px;
    border: 2px solid red;
    border-radius: 5px;
    // &.addressItem:nth-child(n) {
    //   margin-right: 20px;
    //   margin-bottom: 20px;
    // }
    // &.addressItem:nth-child(3n) {
    //   margin-right: 0px;
    // }
    .name {
      padding: 5px;
      margin-bottom: 16px;
      border-bottom: 2px solid #dcdcdc;
    }
    .change {
      width: 50px;
      color: #3387ef;
    }
  }
}
.moreAddress {
  font-size: 18px;
  color: #3387ef;
}
.listBox {
  border: 1px solid #eeeeee;
  .listTitle {
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    color: #585656;
    background: #eeeeee;
    & div:first-child {
      padding-left: 100px;
      width: 340px;
    }
    & div:nth-child(n + 2) {
      width: 160px;
      text-align: center;
    }
  }
  & .productDetail:nth-child(n + 2) {
    border-top: 1px solid #eeeeee;
  }
  .productDetail {
    display: flex;
    padding: 15px 0;
    & > div:first-child {
      padding-left: 20px;
      width: 340px;
    }
    & > div:nth-child(n + 2) {
      padding: 0 20px;
      width: 160px;
      text-align: center;
    }
    .proInfo {
      .proName {
        flex: 1;
      }
    }
    // .mainInfo {
    //   .img {
    //     width: 182px;
    //     height: 161px;
    //   }
    //   .specBox {
    //     height: 160px;
    //     .spec {
    //       min-width: 138px;
    //       padding: 8px 20px;
    //       border: 2px solid red;
    //       border-radius: 2px;
    //       text-align: center;
    //     }
    //     .num {
    //       min-width: 66px;
    //       padding: 2px 20px;
    //       border: 2px solid #9c9c9c;
    //       text-align: center;
    //     }
    //     .activeIcon {
    //       transition: all 0.1s;
    //     }
    //     .activeIcon:hover {
    //       cursor: pointer;
    //       transform: scale(1.2);
    //     }
    //   }
    // }
  }
}
.payBox {
  .payItem {
    height: 35px;
    width: 110px;
    margin-right: 20px;
    border: 2px solid gray;
  }
  .payItem:hover {
    cursor: pointer;
  }
  .activePayItem {
    border: 2px solid orangered;
  }
  .imgBox {
    width: 20px;
    height: 20px;
  }
}
.lineInfo {
  /deep/.el-input {
    width: 300px;
  }
  .lineTitle {
    color: #585656;
    font-size: 16px;
  }
  .grayBox {
    min-width: 216px;
    padding: 4px;
    font-size: 14px;
    border: 2px solid #9c9c9c;
    border-radius: 2px;
  }
}
.radioBox {
  width: 100%;
  flex: 1;
  /deep/.el-radio {
    display: flex;

    align-items: center;
  }
  /deep/.el-radio__inner {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }
}
.payBtn {
  text-align: center;
  .btn {
    width: 211px;
    height: 69px;
    font-size: 25px;
    color: white;
    background: red;
  }
}
.text-deepGray {
  color: #585656;
}
.text-orange {
  color: orange;
}
.title {
  padding-top: 10px;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 18px;
  border-top: 1px dashed #8a8888;
}
.width300 {
  width: 300px;
}
.pl40 {
  padding-left: 40px;
}
.default {
  color: rgb(51, 135, 239);
}
.agent {
  color: #585656;
  width: 300px;
  height: 38px;
  border: 1px solid #dcdfe6;
  border-radius: 5px;
  font-size: 16px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  line-height: 38px;
  padding: 0 1.5%;
}
</style>